
const consentDialog = `<section id="cookie-consent-dialog">
<div id="cookie-consent-dialog--1" class="current view">
  
  <div lang="en">
    <h1>This Website uses Cookies</h1>
    <p>We use cookies to improve our web pages continuously and ensure that they work optimally. By clicking “Accept,” you consent to our use of cookies. 
      Click “Configure” to specify which cookies you wish to accept. For more information, please refer to our <a target="_blank" href="https://www.uni-hamburg.de/en/datenschutz.html">privacy policy</a>.
    </p>
  </div>
  <div class="formbuttons">
    <button type="button" class="formbutton grau" onclick="cookieconsent.show('cookie-consent-dialog--2')"><span lang="en">Config</span></button>
    <button type="button" class="formbutton blau" onclick="cookieconsent.accept()"><span lang="en">Accept</span></button>
  </div>
</div>
<div id="cookie-consent-dialog--2" class="view">
  <div>
    
    <div lang="en">
      <h1>Privacy settings</h1>
      <p>You may select which cookie categories are activated here. You can change your settings anytime.</p>
      <ul class="list">
        <li><em>Necessary:</em> These cookies are necessary for the website to work. They ensure its secure, smooth functioning and cannot be deactivated.</li>
        <li><em>Statistics:</em> These cookies serve the purpose of anonymous web analysis. They help us optimize our website for our users. We cannot identify individual users on the basis of these cookies.</li>
      </ul>
    </div>
    <ul class="checkboxgroup">
      <li>
        <label class="ios-switch-checkbox disabled">
          <input type="checkbox" checked="" disabled="">
          <div><span></span><span lang="en">Necessary</span></div>
        </label>
      </li>
      <li>
        <label class="ios-switch-checkbox" for="cookie-consent-checkbox-stat">
          <input value="cookie-consent-stat" type="checkbox" id="cookie-consent-checkbox-stat">
          <div><span></span><span lang="en">Statistics</span></div>
        </label>
      </li>
    </ul>
  </div>
  <div class="formbuttons">
    <button type="button" class="formbutton blau" onclick="cookieconsent.save()"><span lang="en">Save settings</span></button>
  </div>
  <button type="button" class="x" onclick="cookieconsent.close()"><span lang="en" class="vhidden">Close</span></button>
</div>
</section>`;



// START: request for cookie consent
let _paq = window._paq = window._paq || [];
// console.log(document.cookie.indexOf('_pk_id'));
localStorage.setItem('cookieConsent', localStorage.getItem('cookieConsent') || 0); // 0=not submitted | 1=tracking only | 2=tracking & cookie
_paq.push(['requireCookieConsent']);


if(localStorage.getItem('cookieConsent') == 0 ){
  _paq.push(['forgetCookieConsentGiven']);
}else if(localStorage.getItem('cookieConsent') == 1 ){
  _paq.push(['forgetCookieConsentGiven']);
  enablePiwik()
}else if(localStorage.getItem('cookieConsent') == 2 ){
  _paq.push(['rememberCookieConsentGiven']);
  enablePiwik()
}


// END: request for cookie consent


function enablePiwik(){
  // place your piwik tracking code in here
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);

  (function() {
    var u="//amd.zbh.uni-hamburg.de/analytics/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '5']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
}

window.addEventListener('load', function(){
  console.log(localStorage.getItem('cookieConsent'));
  if(localStorage.getItem('cookieConsent') == 0){
    document.querySelector('body').insertAdjacentHTML('afterBegin', consentDialog);
    const consentDialogEl = document.getElementById('cookie-consent-dialog');
    cookieconsent = {
        save: function(){
            // may include statistics
            console.log(`save`);
            
            if(consentDialogEl.querySelector('#cookie-consent-checkbox-stat').checked){
                // _paq.push(['rememberCookieConsentGiven']);
                // _paq.push(['setCookieConsentGiven']);
              localStorage.setItem('cookieConsent', 2);
            } else {
              localStorage.setItem('cookieConsent', 1);
            }
            // enablePiwik()
            location.reload();
        },

        show: function(targetId){
            // switch displayed target
            consentDialogEl.querySelector('.current.view').classList.remove('current');
            consentDialogEl.querySelector(`#${targetId}`).classList.add('current');
        },

        accept: function(){
            // always incl statistics
            // _paq.push(['rememberCookieConsentGiven']);
            // _paq.push(['setCookieConsentGiven']);
            localStorage.setItem('cookieConsent', 2);
            // enablePiwik();
            location.reload();
        },

        close: function(){
            // switch to default view
            consentDialogEl.querySelector('.current.view').classList.remove('current');
            consentDialogEl.querySelector(`.view`).classList.add('current');
        }
    }
  }
});