const { auto } = require("@popperjs/core");

// start displaying loadingGif
$(document).on('turbolinks:click', function(){
    console.log('click');
    showLoadingGif();
});

function showLoadingGif(){
    $('#loadingGif').fadeIn(50);
    $('body').css('cursor', 'progress');
}
function hideLoadingGif(){
    $('#loadingGif').fadeOut('slow');
    $('body').css('cursor', 'default');
}

$(document).on('submit','form', function(event){
    if(event.target.style.display !== 'none' && event.target.classList.contains('noSpinner')){
        // console.log('hidden forms (i.e. link_to method:post) won\'t trigger loading spinner')
        showLoadingGif();
    };
    
});

$(document).on('change', '.autosubmitForm', function() {
    //fake click on submit
    $(this).closest('form').find('.submit').trigger('click');
});

$(document).on('turbolinks:load', function() {
    t_dr = performance.now();
    console.log('DocumentReady start: '+ new Date().toLocaleTimeString());

    //ease out flash messages
    setTimeout(function(){
        $('#flash').fadeOut();
    }, 10000);
    //enable bootstrap tooltips
    $('[data-toggle="tooltip"]').tooltip();


    requiredFields = document.querySelectorAll('[required]').forEach( requiredField => {
        fieldLabel = document.querySelector(`[for="${requiredField.id}"]`);
        if (fieldLabel){
            fieldLabel.innerHTML += ' <i class="icon required" data-toggle="tooltip" title="required field"></i>';
        }
        $('[data-toggle="tooltip"]').tooltip();
    });


    /**
    define all datatables
    */
    $('.dataTable').each(function() {
        var t_dt = performance.now();
        if ( $.fn.dataTable.isDataTable($(this)) ) {
            $(this).DataTable();
        } else {
            initDataTable($(this));
        }
        console.log('DataTable took: ' + (performance.now() - t_dt) /1000 + ' s');
    });

    // end displaying loadingGif
    hideLoadingGif();

    console.log('DocumentReady took '+(performance.now() - t_dr) /1000 +' s');
});

$(document).on('change', '.form-action-changer', function() {
    if($(this).val()){
        $(this).closest('form').attr('action',  $('option:selected', this).attr('data-action'));
        $(this).closest('form').find('.submit').prop("disabled",false);
    } else {
        $(this).closest('form').attr('action',  '#');
        $(this).closest('form').find('.submit').prop("disabled",true);
    }
});

//this sets multiple permissions at once
$(document).on('change', '.setGlobalExpireDate', function() {
    //act only if date is selected
    if($(this).val()){
        var target_class = $(this).attr('data-target');
        var new_date = $(this).val();
        if(target_class){
            //$('.activePermission').val($(this).val()).trigger('change');
            $('.form-control.'+ target_class).each(function(index){
                var control = $(this);
                $(control).val(new_date).trigger('change');
                console.log(index);
            });
        } else {
            console.log('could not set dates, because of missing attribute data-target');
        }
    }
});

$(document).on('blur', 'input.autosubmit, select.autosubmit', function(event){
    // handle checkbox or text
    const value = (event.target.type == 'checkbox' ? event.target.checked : event.target.value)
    // prepare subitted data
    const data = {
        [event.target.dataset.class]: {
            [event.target.name]: value
        }
    }
    // submit data
    $.ajax({
        url: event.target.dataset.target,
        type: 'PATCH',
        dataType: 'json',
        data: data,
        success: function( data, textStatus, jqXhr ){
            hideLoadingGif();
            console.log('hurray')
        }
    });
});




function initDataTable(table){
    //set presets
    t_it = performance.now();
    const tablename = table.attr('id');
    let noSortCols = [];
    let orderByCols = [];
    //see if slim -> suppress funktions like pagination
    const extendedVersion = ($('#'+ tablename).hasClass('slim') ? false : true );
    //loop through columns
    $('#'+ tablename +' thead th').each( function(i){
        //no-sort columns
        if ($(this).hasClass('no-sort')){ //(typeof fixed !== typeof undefined && fixed !== false) {
            noSortCols.push(i);
        }
        //pre-sort columns - !!!! minimum one per table needed
        order = $(this).attr('col-orderBy');
        if (typeof order !== typeof undefined && order !== false) {
            orderByCols.push(i, order);
        }
    });

    console.log('Init table took '+(performance.now() - t_it) /1000 +' s')
    t_ct = performance.now();

    //finally initialize the dataTable
    var table = $('#'+ tablename).DataTable({
        'stateSave'   : true,
        'order'       : orderByCols,
        'info'        : extendedVersion,
        'searching'   : extendedVersion,
        'paging'      : extendedVersion,
        'columnDefs'  : [
            {
                targets: noSortCols, 
                orderable: false,
                className: 'dtr-control'
            }
        ],
        "initComplete": function(settings, json) {
            console.log('Create table took '+(performance.now() - t_ct) /1000 +' s')
        }
    });
    console.log('Create table took '+(performance.now() - t_ct) /1000 +' s')
}